export const contactMailsend = (details, laoding) =>
  new Promise((resolve, reject) => {
    const baseUrl =
      "https://us-central1-percko-website.cloudfunctions.net/sendMail/contact"
    fetch(baseUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then(response => response.json())
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        laoding(false)
        console.log(err)
        reject(err)
      })
  })

export const contactSavMailsend = (details, laoding) =>
  new Promise((resolve, reject) => {
    const baseUrl =
      "https://us-central1-percko-website.cloudfunctions.net/sendMail/contactsav"
    fetch(baseUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then(response => response.json())
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        laoding(false)
        console.log(err)
        reject(err)
      })
  })

export const contactHotelMailsend = (details, laoding) =>
  new Promise((resolve, reject) => {
    const baseUrl =
      "https://us-central1-percko-website.cloudfunctions.net/sendMail/contacthotel"
    fetch(baseUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then(response => response.json())
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        laoding(false)
        console.log(err)
        reject(err)
      })
  })
